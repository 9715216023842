import React, { Component } from 'react'

import EventsManager from 'support/components/eventsmanager/EventsManager'
import { TemaEnum } from 'support/style/Tema'

class RouterContent extends Component {
  constructor(props) {
    super(props)
    if (this.props.denifirTemaPadrao) {
      EventsManager.pub('AlterarTemaAplicacao', {
        tema: TemaEnum.PRETO_AZUL
      })
    }
  }

  componentDidMount() {
    this.url = window.document.location.pathname
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.url === window.document.location.pathname) {
      const propsClone = Object.assign({}, this.props)
      const nextPropsClone = Object.assign({}, nextProps)
      delete propsClone.history
      delete nextPropsClone.history
      delete propsClone.location
      delete nextPropsClone.location
      delete propsClone.mobileDrawerOpen
      delete nextPropsClone.mobileDrawerOpen

      const filter = function (key, value) {
        if (key.charAt(0) === '_' || key === 'current') {
          return
        }
        return value
      }

      const propsCloneString = JSON.stringify(propsClone, filter)
      const nextPropsCloneString = JSON.stringify(nextPropsClone, filter)

      if (propsCloneString === nextPropsCloneString) {
        //console.log("OTIMIZACAO,RENDER","RouterContent", this.url, propsCloneString, nextPropsCloneString);
        return false
      }
    }

    if (this.props.sendPageViewGA && window.gtag) {
      window.setTimeout(() => {
        const page = window.document.location.pathname.replace(/\/c[0-9]{1,}n[0-9]{1,}/gi, '')
        window.gtag('config', 'UA-113212457-1', { page_path: page })
      }, 200)
    }

    //console.log("SLOW","RouterContent", this.url);
    this.url = window.document.location.pathname
    return true
  }

  render() {
    const { component: ComponentToRender, ...others } = this.props
    return <ComponentToRender {...others} />
  }
}

export default RouterContent
