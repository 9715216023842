import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import { withConfigCatClient } from 'configcat-react'
import PainelAdmin from 'pages/admin/painel/PainelAdmin'
import ContaPagesManager from 'pages/conta/ContaPagesManager'
import ErpPagesManager from 'pages/erp/ErpPagesManager'
import PainelErp from 'pages/erp/painel/PainelErp'
import InputsPage from 'pages/framework/InputsPage'
import PortalMainLoader from 'pages/portal/PortalMainLoader'
import { ROUTE_ADMIN_PANEL, ROUTE_CONTA_MAIN, ROUTE_ENTERPRISE_MAIN, ROUTE_ENTERPRISE_PANEL } from 'pages/RouteMap'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import RouterContent from 'support/components/router/RouterContent'

class AppRoute extends Component {
  constructor(props) {
    super(props)
    this.eventsManager = EventsManager.new()
    this.props.configCatClient.forceRefreshAsync()
  }

  componentDidMount = () => {
    this.eventsManager.sub('RoutePush', (props) => {
      this.props.history.push(props.path)
    })
    this.eventsManager.sub('RouteReplace', (props) => {
      this.props.history.replace(props.path)
    })
  }

  render() {
    return (
      <Switch>
        <Route path="/framework/inputs" render={(props) => <RouterContent component={InputsPage} />} denifirTemaPadrao={true} />

        <Route path={ROUTE_CONTA_MAIN} render={(props) => <RouterContent component={ContaPagesManager} denifirTemaPadrao={true} {...this.props} />} />

        <Route path={ROUTE_ENTERPRISE_PANEL} render={(props) => <RouterContent component={PainelErp} denifirTemaPadrao={true} {...this.props} />} />

        <Route path={ROUTE_ENTERPRISE_MAIN} render={(props) => <RouterContent component={ErpPagesManager} denifirTemaPadrao={true} {...this.props} />} />

        <Route path={ROUTE_ADMIN_PANEL} render={(props) => <RouterContent component={PainelAdmin} denifirTemaPadrao={true} {...this.props} />} />

        <Route path={'/'} render={(props) => <RouterContent component={PortalMainLoader} denifirTemaPadrao={true} {...this.props} />} />
      </Switch>
    )
  }

  componentWillUnmount() {
    this.eventsManager.unsubscribe()
  }
}

AppRoute.propTypes = {
  configCatClient: PropTypes.object.isRequired
}

export default withConfigCatClient(AppRoute)
