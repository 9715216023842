import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { RegraCorCardEnum } from 'pages/erp/painel/atendimento/agendainterface/Agenda'
import { COLUNA_ITENS_MIN_WIDTH, COLUNA_ITENS_WIDTH_DEFAULT, INTERVALO_MINUTOS_EXIBICAO_TEMPO_DEFAULT } from 'pages/erp/painel/atendimento/agendainterface/Agenda'
import { PerspectivaEnum } from 'pages/erp/painel/atendimento/AtendimentosPanelPage'
import PropTypes from 'prop-types'
import EventsManager from 'support/components/eventsmanager/EventsManager'
import InputSelect from 'support/components/input/InputSelect'
import SelectableContent from 'support/components/list/SelectableContent'
import Slider from 'support/components/slider/Slider'
import { createEnum } from 'support/util/EnumUtil'
import { formatarNome } from 'support/util/TextFormatter'
import { formatarDuracao } from 'support/util/TimeFormatter'

const styles = (theme) => ({})

export const LimiteExibicaoEnum = createEnum({
  UM: { id: 1, descricao: 'Até 1 Atendimento', valor: 1 },
  DOIS: { id: 2, descricao: 'Até 2 Atendimentos', valor: 2 },
  TRES: { id: 3, descricao: 'Até 3 Atendimentos', valor: 3 },
  QUATRO: { id: 4, descricao: 'Até 4 Atendimentos', valor: 4 },
  CINCO: { id: 5, descricao: 'Até 5 Atendimentos', valor: 5 },
  SEIS: { id: 6, descricao: 'Até 6 Atendimentos', valor: 6 },
  SETE: { id: 7, descricao: 'Até 7 Atendimentos', valor: 7 },
  OITO: { id: 8, descricao: 'Até 8 Atendimentos', valor: 8 },
  NOVE: { id: 9, descricao: 'Até 9 Atendimentos', valor: 9 },
  DEZ: { id: 10, descricao: 'Até 10 Atendimentos', valor: 10 },
  QUINZE: { id: 15, descricao: 'Até 15 Atendimentos', valor: 15 },
  TODOS: { id: 20, descricao: 'Todos', valor: 999 }
})

class Configuracoes extends Component {
  diminuirIntervaloMinutosExibicaoTempo = (acao) => {
    let { intervaloMinutosExibicaoTempo } = this.props
    if (intervaloMinutosExibicaoTempo === 240) {
      intervaloMinutosExibicaoTempo = 120
    } else if (intervaloMinutosExibicaoTempo === 120) {
      intervaloMinutosExibicaoTempo = 60
    } else if (intervaloMinutosExibicaoTempo === 60) {
      intervaloMinutosExibicaoTempo = 30
    } else if (intervaloMinutosExibicaoTempo === 30) {
      intervaloMinutosExibicaoTempo = 15
    } else if (intervaloMinutosExibicaoTempo === 15) {
      intervaloMinutosExibicaoTempo = 10
    } else if (intervaloMinutosExibicaoTempo === 10) {
      intervaloMinutosExibicaoTempo = 5
    }
    this.props.changeIntervaloMinutosExibicaoTempo(intervaloMinutosExibicaoTempo)
  }

  aumentarIntervaloMinutosExibicaoTempo = (acao) => {
    let { intervaloMinutosExibicaoTempo } = this.props
    if (intervaloMinutosExibicaoTempo === 120) {
      intervaloMinutosExibicaoTempo = 240
    } else if (intervaloMinutosExibicaoTempo === 60) {
      intervaloMinutosExibicaoTempo = 120
    } else if (intervaloMinutosExibicaoTempo === 30) {
      intervaloMinutosExibicaoTempo = 60
    } else if (intervaloMinutosExibicaoTempo === 15) {
      intervaloMinutosExibicaoTempo = 30
    } else if (intervaloMinutosExibicaoTempo === 10) {
      intervaloMinutosExibicaoTempo = 15
    } else if (intervaloMinutosExibicaoTempo === 5) {
      intervaloMinutosExibicaoTempo = 10
    }
    this.props.changeIntervaloMinutosExibicaoTempo(intervaloMinutosExibicaoTempo)
  }

  handleChangeColunaWidth = (event, value) => {
    if (value < COLUNA_ITENS_MIN_WIDTH) {
      value = COLUNA_ITENS_MIN_WIDTH
    }
    this.props.changeColunaWidth(value)
  }

  render() {
    const maxValueSlider = this.props.containerColunasWidth - 40
    const minValueSlider = COLUNA_ITENS_MIN_WIDTH - ((maxValueSlider - COLUNA_ITENS_MIN_WIDTH) * 7) / 100

    let isPerspectivaDia = false
    let isPerspectivaSemana = false
    let isPerspectivaMes = false

    if (this.props.perspectiva.id === PerspectivaEnum.DIA.id) {
      isPerspectivaDia = true
    } else if (this.props.perspectiva.id === PerspectivaEnum.SEMANA.id) {
      isPerspectivaSemana = true
    } else if (this.props.perspectiva.id === PerspectivaEnum.MES.id) {
      isPerspectivaMes = true
    }

    return (
      <div style={{ padding: '0px 0px 6px' }}>
        {(isPerspectivaDia || isPerspectivaSemana) && (
          <React.Fragment>
            <div style={{ height: 46 }}>
              <Grid container style={{ height: '100%' }} alignItems="center">
                <Grid item xs>
                  <span variant="body1" style={{ fontSize: 14, color: '#888888' }}>
                    Largura das colunas
                  </span>
                </Grid>
                <Grid item style={{ visibility: this.props.colunaWidth !== COLUNA_ITENS_WIDTH_DEFAULT ? 'hidden' : 'hidden' }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    style={{
                      minWidth: 0,
                      border: '1px solid #eaeaea',
                      textTransform: 'none',
                      height: 32,
                      paddingTop: 0,
                      paddingBottom: 0,
                      lineHeight: '32px',
                      paddingLeft: 8,
                      paddingRight: 8
                    }}
                    onClick={() => {
                      this.props.changeColunaWidth(null, COLUNA_ITENS_WIDTH_DEFAULT)
                    }}
                  >
                    <span style={{ fontSize: 11, color: '#505050' }}>Redefinir</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div style={{ padding: '0px 3px' }}>
              <Grid container style={{ height: '100%' }} alignItems="center">
                <Grid item xs>
                  <Slider
                    disabled={this.props.colunaAutoWidth}
                    step={10}
                    min={minValueSlider}
                    max={maxValueSlider}
                    value={this.props.colunaWidth}
                    onChange={this.handleChangeColunaWidth}
                  />
                </Grid>
                <Grid item align="right" style={{ width: 90 }}>
                  <FormControlLabel
                    label={<Typography variant="body2">Auto</Typography>}
                    style={{ marginRight: 4 }}
                    control={
                      <Checkbox
                        style={{ padding: 8 }}
                        size="small"
                        checked={this.props.colunaAutoWidth}
                        color="primary"
                        onChange={(event) => {
                          const checked = event.target.checked
                          this.props.changeColunaAutoWidth(checked)
                          //this.setState({colunaAutoWidth : checked});
                          if (checked) {
                            EventsManager.pub('AjustarAutoWidthColunasItens', { force: true })
                          }
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </div>

            <div style={{ height: 46 }}>
              <Grid container style={{ height: '100%' }} alignItems="center">
                <Grid item xs>
                  <span variant="body1" style={{ fontSize: 14, color: '#888888' }}>
                    Intervalo de tempo
                  </span>
                </Grid>
                <Grid item style={{ visibility: this.props.intervaloMinutosExibicaoTempo !== INTERVALO_MINUTOS_EXIBICAO_TEMPO_DEFAULT ? 'hidden' : 'hidden' }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    style={{
                      minWidth: 0,
                      border: '1px solid #eaeaea',
                      textTransform: 'none',
                      height: 32,
                      paddingTop: 0,
                      paddingBottom: 0,
                      lineHeight: '32px',
                      paddingLeft: 8,
                      paddingRight: 8
                    }}
                    onClick={() => {
                      this.props.changeIntervaloMinutosExibicaoTempo(INTERVALO_MINUTOS_EXIBICAO_TEMPO_DEFAULT)
                    }}
                  >
                    <span style={{ fontSize: 11, color: '#505050' }}>Redefinir</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div style={{ padding: '8px 0px' }}>
              <Grid container justify="center" alignItems="center">
                <Grid item xs>
                  <Typography variant="body2">
                    {formatarDuracao(this.props.intervaloMinutosExibicaoTempo)}
                    {this.props.intervaloMinutosExibicaoTempo === 30 ? (
                      <span style={{ marginLeft: 8 }}>
                        <small>
                          <b>(Padrão)</b>
                        </small>
                      </span>
                    ) : (
                      ''
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={this.props.intervaloMinutosExibicaoTempo <= 10}
                    style={{ padding: 6 }}
                    onClick={() => {
                      this.diminuirIntervaloMinutosExibicaoTempo()
                    }}
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    disabled={this.props.intervaloMinutosExibicaoTempo === 240}
                    style={{ padding: 6 }}
                    onClick={() => {
                      this.aumentarIntervaloMinutosExibicaoTempo()
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        )}

        {isPerspectivaMes && (
          <React.Fragment>
            <div style={{ height: 46, lineHeight: '46px' }}>
              <span variant="body1" style={{ fontSize: 14, color: '#888888' }}>
                Limite de Exibição
              </span>
            </div>
            <div style={{}}>
              <InputSelect
                customVariant="naked"
                nakedLeftPadding={false}
                fullWidth={false}
                InputProps={{
                  style: {
                    fontSize: 14
                  }
                }}
                value={this.props.numeroMaximoExibicaoAtendimentosMes}
                onChange={(event) => {
                  this.props.changeNumeroMaximoExibicaoAtendimentosMes(parseInt(event.target.value, 10))
                }}
                style={{ marginTop: 0 }}
              >
                {LimiteExibicaoEnum.values().map((opcao) => (
                  <MenuItem key={opcao.id} value={opcao.valor}>
                    {opcao.descricao}
                  </MenuItem>
                ))}
              </InputSelect>
            </div>
          </React.Fragment>
        )}

        <div style={{ height: 46, lineHeight: '46px' }}>
          <span variant="body1" style={{ fontSize: 14, color: '#888888' }}>
            Cores dos agendamentos
          </span>
        </div>
        <div style={{}}>
          <InputSelect
            customVariant="naked"
            nakedLeftPadding={false}
            fullWidth={false}
            InputProps={{
              style: {
                fontSize: 14
              }
            }}
            value={this.props.regraCorCard.id}
            onChange={(event) => {
              const id = event.target.value
              this.props.changeRegraCorCard(RegraCorCardEnum.getById(id))
            }}
            style={{ marginTop: 0 }}
          >
            {RegraCorCardEnum.values().map((regra) => (
              <MenuItem key={regra.id} value={regra.id}>
                {regra.descricao}
              </MenuItem>
            ))}
          </InputSelect>
        </div>

        {isPerspectivaDia && (
          <div style={{ padding: '14px 0px 8px' }}>
            <span variant="body1" style={{ fontSize: 14, color: '#888888' }}>
              Profissionais
            </span>
            {this.props.colaboradores.map((colaborador, index) => {
              return (
                <div key={colaborador.id}>
                  <SelectableContent
                    key={index}
                    style={{ marginTop: index === 0 ? 20 : 10 }}
                    color="primary"
                    onChange={(event) => {
                      this.props.changeExibicaoColaborador(colaborador.id, event.target.checked)
                    }}
                    checked={colaborador._show}
                    label={<Typography variant="body2">{formatarNome(colaborador.apelido, 2)}</Typography>}
                    selectable={true}
                  />
                </div>
              )
            })}
          </div>
        )}

        {(isPerspectivaSemana || isPerspectivaMes) && (
          <div style={{ padding: '14px 0px 8px' }}>
            <span variant="body1" style={{ fontSize: 14, color: '#888888' }}>
              Dias da Semana
            </span>
            {this.props.diasSemana.map((diaSemana, index) => {
              return (
                <div key={diaSemana.id}>
                  <SelectableContent
                    key={index}
                    style={{ marginTop: index === 0 ? 20 : 10 }}
                    color="primary"
                    onChange={(event) => {
                      this.props.changeExibicaoDiasSemana(diaSemana.id, event.target.checked)
                    }}
                    checked={diaSemana._show}
                    label={<Typography variant="body2">{diaSemana.nome}</Typography>}
                    selectable={true}
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

/*function ItemMenuConfiguracaoGrid(props){

	return (
		<MenuItem style={props.style} onClick={props.onClick}>
			<Grid
				container
				style={{width: "100%"}}
				justify="center"
				alignItems="center">
				<Grid item  xs align="left">
					{props.label}
				</Grid>
				{
					props.selected &&
					<Grid item align="right" style={{paddingLeft:20}}>
						<CheckIcon />
					</Grid>
				}
			</Grid>
		</MenuItem>);
}*/

Configuracoes.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Configuracoes)
