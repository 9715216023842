import { Component } from 'react'

import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PropTypes from 'prop-types'
import { openBackableUnmanaged } from 'support/components/backablecomponentsmanager/BackableComponentsManager'
import { converteHexStrToRgbStr } from 'support/util/ColorUtil'

const styles = (theme) => ({
  showMobile: theme.util.showMobile(),
  showDesktop: theme.util.showDesktop(),
  toolbarButton: {
    padding: 8,
    '&:last-child': {
      marginRight: -12
    }
  },
  expandedMenuItemLabel: {
    marginRight: 14
  },
  disabled: {
    //color: "rgba(255, 255, 255, 0.26) !important"
  },
  button: {
    '&:last-child': {
      marginRight: -8
    }
  },
  rootMenuButton: {
    marginLeft: 8,
    textTransform: 'none'
  },
  outlinedMenuButton: {
    borderColor: '#dadce0',
    padding: '6px 7px 4px 13px'
  },
  endIconMenuButton: {
    marginLeft: 4
  },
  iconBadge: {
    color: theme.palette.badge.primaryContrast.text,
    backgroundColor: theme.palette.badge.primaryContrast.background,
    animation: '$pulse 1.5s infinite'
  },
  '@keyframes pulse': {
    '0%': {
      ...theme.util.createBoxShadow('0 0 0 0 ' + converteHexStrToRgbStr(theme.palette.badge.primaryContrast.background, 1))
    },
    '85%': {
      ...theme.util.createBoxShadow('0 0 0 14px ' + converteHexStrToRgbStr(theme.palette.badge.primaryContrast.background, 0))
    },
    '100%': {
      ...theme.util.createBoxShadow('0 0 0 0 ' + converteHexStrToRgbStr(theme.palette.badge.primaryContrast.background, 0))
    }
  }
})

class ToolbarActions extends Component {
  TOOLBAR_ACTION_DESKTOP_ITEMS_SHOW = 4
  TOOLBAR_ACTION_MOBILE_ITEMS_SHOW = 4

  constructor(props) {
    super(props)

    if (props.desktopItemsShow !== undefined) {
      this.TOOLBAR_ACTION_DESKTOP_ITEMS_SHOW = props.desktopItemsShow
    }
    if (props.mobileItemsShow !== undefined) {
      this.TOOLBAR_ACTION_MOBILE_ITEMS_SHOW = props.mobileItemsShow
    }

    this.state = {
      desktopExpandedMenuAnchorEl: null,
      mobileExpandedMenuAnchorEl: null
    }
  }

  handleDesktopMenuOpen = (event) => {
    this.setState({ desktopExpandedMenuAnchorEl: event.currentTarget })
  }

  handleDesktopMenuClose = () => {
    this.setState({ desktopExpandedMenuAnchorEl: null })
  }

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileExpandedMenuAnchorEl: event.currentTarget })
  }

  handleMobileMenuClose = () => {
    this.setState({ mobileExpandedMenuAnchorEl: null })
  }

  performItemClick = (event, action) => {
    action.handleAction(event)
  }

  performSubItemClick = (event, action, handleDesktopMenuClose) => {
    handleDesktopMenuClose()
    action.handleAction(event)
  }

  isActionDisabled = (action) => {
    if ((action.disableWhenAjaxing && this.props.ajaxing) || action.disabled) {
      return true
    }
    return false
  }

  render() {
    const { classes } = this.props

    let toolbarActionDesktop = []
    let toolbarActionMobile = []
    let toolbarActionDesktopExpanded = []
    let toolbarActionMobileExpanded = []

    if (
      this.props.toolbarActions !== undefined &&
      this.props.toolbarActions !== null &&
      this.props.toolbarActions.actions !== undefined &&
      this.props.toolbarActions.actions !== null &&
      this.props.toolbarActions.actions.length > 0
    ) {
      let desktopItemsShow = this.TOOLBAR_ACTION_DESKTOP_ITEMS_SHOW
      if (this.props.toolbarActions.desktopItemsShow !== undefined && this.props.toolbarActions.desktopItemsShow !== null) {
        desktopItemsShow = this.props.toolbarActions.desktopItemsShow
      }

      let mobileItemsShow = this.TOOLBAR_ACTION_MOBILE_ITEMS_SHOW
      if (this.props.toolbarActions.mobileItemsShow !== undefined && this.props.toolbarActions.mobileItemsShow !== null) {
        mobileItemsShow = this.props.toolbarActions.mobileItemsShow
      }

      const actions = this.props.toolbarActions.actions.filter((action) => action.show === undefined || action.show === true)

      actions.forEach((action, index) => {
        const disabled = this.isActionDisabled(action)

        if (action.disableWhenAjaxing === undefined) {
          action.disableWhenAjaxing = true
        }

        let toolbarActionItem
        if (action.menu) {
          toolbarActionItem = (
            <Button
              disabled={disabled}
              key={index}
              color="inherit"
              classes={{
                root: classes.rootMenuButton,
                outlined: classes.outlinedMenuButton,
                endIcon: classes.endIconMenuButton,
                disabled: classes.disabled
              }}
              variant="outlined"
              endIcon={<ArrowDropDownIcon />}
              onClick={(event) => {
                let target = event.currentTarget
                openBackableUnmanaged({
                  onCreateInstance: (instance) => {
                    this.menuButtonMenuBackableComponentInstance = instance
                    this.setState({
                      menuButtonMenuAnchorEl: target,
                      menuButtonMenuItems: action.menu.items,
                      menuButtonMenuHandleClick: action.menu.onClick
                    })
                  },
                  onClosed: () => {
                    this.setState({ menuButtonMenuAnchorEl: false })
                  }
                })
              }}
            >
              {action.menu.value.text}
            </Button>
          )
        } else if (action.icon !== undefined && action.icon !== null) {
          toolbarActionItem = (
            <IconButton
              data-testid={action['data-testid']}
              className={classes.toolbarButton}
              disabled={disabled}
              key={index}
              color="inherit"
              classes={{ disabled: classes.disabled }}
              onClick={(event) => this.performItemClick(event, action)}
            >
              <Badge classes={{ badge: classes.iconBadge }} badgeContent={action.badgeValue ? action.badgeValue : null} max={9}>
                {action.icon}
              </Badge>
            </IconButton>
          )
        } else {
          toolbarActionItem = (
            <Button
              data-testid={action['data-testid']}
              disabled={disabled}
              key={index}
              color="inherit"
              className={classes.button}
              classes={{ disabled: classes.disabled }}
              onClick={(event) => this.performItemClick(event, action)}
            >
              {action.label.toUpperCase()}
            </Button>
          )
        }
        if (action.showOnlyMobile === undefined || action.showOnlyMobile === false) {
          if (index + 1 < desktopItemsShow) {
            toolbarActionDesktop.push(toolbarActionItem)
          } else if (index + 1 === desktopItemsShow) {
            if (index + 1 === actions.length) {
              toolbarActionDesktop.push(toolbarActionItem)
            } else {
              toolbarActionDesktop.push(
                <IconButton
                  className={classes.toolbarButton}
                  disabled={disabled}
                  key={index}
                  classes={{ disabled: classes.disabled }}
                  onClick={this.handleDesktopMenuOpen}
                  color="inherit"
                >
                  <MoreVertIcon />
                </IconButton>
              )
            }
          }
          if (index + 1 > desktopItemsShow || (index + 1 === desktopItemsShow && index + 1 < actions.length)) {
            toolbarActionDesktopExpanded.push(
              <MenuItem
                disabled={disabled}
                key={index}
                onClick={(event) => {
                  this.performSubItemClick(event, action, this.handleDesktopMenuClose)
                }}
              >
                <IconButton color="inherit" classes={{ disabled: classes.disabled }} style={{ padding: 0, marginRight: 12 }}>
                  {action.icon}
                </IconButton>
                <span className={classes.expandedMenuItemLabel}>{action.label}</span>
              </MenuItem>
            )
          }
        }

        if (action.showOnlyDesktop === undefined || action.showOnlyDesktop === false) {
          if (index + 1 < mobileItemsShow) {
            toolbarActionMobile.push(toolbarActionItem)
          } else if (index + 1 === mobileItemsShow) {
            if (index + 1 === actions.length) {
              toolbarActionMobile.push(toolbarActionItem)
            } else {
              toolbarActionMobile.push(
                <IconButton
                  className={classes.toolbarButton}
                  disabled={disabled}
                  key={index}
                  classes={{ disabled: classes.disabled }}
                  onClick={this.handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreVertIcon />
                </IconButton>
              )
            }
          }

          if (index + 1 > mobileItemsShow || (index + 1 === mobileItemsShow && index + 1 < actions.length)) {
            toolbarActionMobileExpanded.push(
              <MenuItem
                disabled={disabled}
                key={index}
                onClick={(event) => {
                  this.performSubItemClick(event, action, this.handleMobileMenuClose)
                }}
              >
                <IconButton color="inherit" classes={{ disabled: classes.disabled }} style={{ padding: 0, marginRight: 12 }}>
                  {action.icon}
                </IconButton>
                <span className={classes.expandedMenuItemLabel}>{action.label}</span>
              </MenuItem>
            )
          }
        }
      })
    }

    let desktopExpandedMenuContent = null
    if (toolbarActionDesktopExpanded.length > 0) {
      desktopExpandedMenuContent = (
        <Menu
          anchorEl={this.state.desktopExpandedMenuAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(this.state.desktopExpandedMenuAnchorEl)}
          onClose={this.handleDesktopMenuClose}
        >
          {toolbarActionDesktopExpanded}
        </Menu>
      )
    }

    let mobileExpandedMenuContent = null
    if (toolbarActionMobileExpanded.length > 0) {
      mobileExpandedMenuContent = (
        <Menu
          anchorEl={this.state.mobileExpandedMenuAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(this.state.mobileExpandedMenuAnchorEl)}
          onClose={this.handleMobileMenuClose}
        >
          {toolbarActionMobileExpanded}
        </Menu>
      )
    }

    const containerStyle = { whiteSpace: 'nowrap' }

    if (this.props.toolbarActions && this.props.toolbarActions.container && this.props.toolbarActions.container.marginRight) {
      containerStyle.marginRight = this.props.toolbarActions.container.marginRight
    }

    return (
      <div style={containerStyle}>
        <div className={classes.showDesktop}>{toolbarActionDesktop}</div>
        <div className={classes.showMobile}>{toolbarActionMobile}</div>
        <div className={classes.showDesktop}>{desktopExpandedMenuContent}</div>
        <div className={classes.showMobile}>{mobileExpandedMenuContent}</div>
        <Menu
          anchorEl={this.state.menuButtonMenuAnchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(this.state.menuButtonMenuAnchorEl)}
          onClose={this.menuButtonMenuBackableComponentInstance ? this.menuButtonMenuBackableComponentInstance.props.handleClose : null}
        >
          {this.state.menuButtonMenuItems &&
            this.state.menuButtonMenuItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={(event) => {
                  this.menuButtonMenuBackableComponentInstance.props.handleClose({
                    onClosedCallback: () => {
                      this.state.menuButtonMenuHandleClick(item)
                    }
                  })
                }}
              >
                {item.label}
              </MenuItem>
            ))}
        </Menu>
      </div>
    )
  }
}

ToolbarActions.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ToolbarActions)
